import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const HeaderA = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate(); 

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClick = (actualLink, displayUrl) => {
        navigate(actualLink);
        window.history.replaceState(null, "", displayUrl);
    };

    const propertyLinks = {
        "Flat/Apartment": [
            { name: "1 BHK", link: "/propertylist/apartment-flat/bedrooms-1", displayUrl: "/real-estate-1bhk-apartment-flat-for-sale" },
            { name: "2 BHK", link: "/propertylist/apartment-flat/bedrooms-2", displayUrl: "/real-estate-2bhk-apartment-flat-for-sale" },
            { name: "3 BHK", link: "/propertylist/apartment-flat/bedrooms-3", displayUrl: "/real-estate-3bhk-apartment-flat-for-sale" },
            { name: "4 BHK", link: "/propertylist/apartment-flat/bedrooms-4", displayUrl: "/real-estate-4bhk-apartment-flat-for-sale" },
        ],
        "House/Villas": [
            { name: "1 BHK", link: "/propertylist/house-villa/bedrooms-1", displayUrl: "/real-estate-1bhk-independent-house-villa-for-sale" },
            { name: "10 BHK", link: "/propertylist/house-villa/bedrooms-10", displayUrl: "/real-estate-10bhk-independent-house-villa-for-sale" },
            { name: "11 BHK", link: "/propertylist/house-villa/bedrooms-11", displayUrl: "/real-estate-11bhk-independent-house-villa-for-sale" },
            { name: "2 BHK", link: "/propertylist/house-villa/bedrooms-2", displayUrl: "/real-estate-2bhk-independent-house-villa-for-sale" },
            { name: "3 BHK", link: "/propertylist/house-villa/bedrooms-3", displayUrl: "/real-estate-3bhk-independent-house-villa-for-sale" },
            { name: "4 BHK", link: "/propertylist/house-villa/bedrooms-4", displayUrl: "/real-estate-4bhk-independent-house-villa-for-sale" },
            { name: "5 BHK", link: "/propertylist/house-villa/bedrooms-5", displayUrl: "/real-estate-5bhk-independent-house-villa-for-sale" },
            { name: "6 BHK", link: "/propertylist/house-villa/bedrooms-6", displayUrl: "/real-estate-6bhk-independent-house-villa-for-sale" },
        ],
    };

    const { property_no } = useParams();
    const [propertyData, setPropertyData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPropertyData = async () => {
            setLoading(true);
            console.log("Fetching data for property_no:", property_no);

            try {
                const response = await fetch(`/api/SingleProperty.php?property_id=${property_no}`);
                const result = await response.json();

                console.log("API Response:", result);

                if (result.property_details) {
                    setPropertyData(result);
                } else {
                    console.log("No property data found for this property_no");
                    setPropertyData(null);
                }
            } catch (error) {
                console.error("Error fetching property data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPropertyData();
    }, [property_no]);

    // Extract the logo image dynamically
    const logoEntry = propertyData?.website_images?.find(img => img.image_type?.toLowerCase().trim() === "logo");



    // Safely extract logo image name and ID
    const imageName  = logoEntry?.image_name || null;
    const logoId = logoEntry?.id_website_image || null;

    // Log only if website_images is present
    // Log website images properly
useEffect(() => {
    if (propertyData && Object.keys(propertyData).length > 0) {
        if (Array.isArray(propertyData.website_images) && propertyData.website_images.length > 0) {
            console.log("✅ Website Images:", propertyData.website_images);
        } else {
            console.warn("⚠️ Warning: `website_images` is missing or empty in the API response.");
        }
    } else {
        console.log("⏳ Waiting for API response...");
        console.log("Fetched Image Name:", imageName);
    }
}, [propertyData]);

    return (
        <>
            <style>
                {`
/* Base Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
}

img {
    width: 220px;
    height: auto;
}

.header {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 10px 20px;
    width: 100%;
    box-sizing: border-box;
}

.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0; /* Reset gap here */
}

.header-inner-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 94%;
}

#site-logo {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Reduce gap between logo and nav items */
}

/* Navigation Menu */
.main-menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 20px; /* Adds spacing between menu items */
}

.main-menu li {
    display: inline-block;
    position: relative;
}

.main-menu a {
    text-decoration: none;
    color: #333;
    display: inline-block;
    font-size: 20px; /* Slightly larger font size for better visibility */
    padding: 5px 10px; /* Add some padding around the text for better spacing */
}

/* Dropdown Menu */
.main-menu .has-children ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-width: 165px;
    padding: 10px;
}

.main-menu .has-children:hover > ul {
    display: block;
}

.main-menu .has-children ul li {
    display: block; /* Ensures list items are stacked vertically */
    width: 100%;
    padding: 3px 0; /* Adds spacing between items */
    text-align: left;

}

.main-menu .has-children ul li:last-child {
    border-bottom: none; /* Removes border from the last item */
}

.main-menu .has-children ul li button {
    width: 100%;
    background: none;
    border: none;
    text-align: left;
    font-size: 18px;
    padding: 7px;
    cursor: pointer;
    color: #333;
    display: block;
}

.main-menu .has-children ul li button:hover {
    background-color: #f0f0f0;
}

/* Header Right Section */
.header-right {
    display: flex;
    align-items: center;
    gap: 15px; /* Reduced gap between the phone icon and the button */
}

.header-call {
    display: flex;
    align-items: center;
}

.header-call .number {
    margin-left: 5px;
}

.header-btn a {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    text-decoration: none;
    border-radius: 5px;
}

/* Mobile Navigation */
.mobile-nav-toggler {
    display: none;
}

/* Mobile and Tablet Styles */
@media (max-width: 1024px) {
    .header-inner-wrap {
        flex-direction: column;
        align-items: flex-start;
        
    }

    .main-menu {
        flex-direction: column;
        display: none;
        width: 100%;
    }

    .main-menu.open {
        display: block;
    }

    .header-right {
        margin-top: 20px;
        flex-direction: column;
    }

    .header-right .header-call {
        margin-bottom: 10px;
    }

    .mobile-nav-toggler {
        display: block;
        background: none;
        border: none;
        font-size: 25px;
        cursor: pointer;
        color: #333;
    }

    .main-menu li {
        width: 100%;
        padding: 10px 0;
        text-align: left;
    }
}

/* Desktop Styles */
@media (min-width: 1024px) {
    .header-inner-wrap {
        flex-direction: row;
    }

    .main-menu {
        flex-direction: row;
    }

    .mobile-nav-toggler {
        display: none;
    }
}

/* Ensure banner section is not hidden under the header */
.banner {
    margin-top: 80px; /* Adjust the margin to ensure it doesn't hide under the fixed header */
}


                `}
            </style>

            <header className="header">
                <div className="header-inner">
                    <div className="header-inner-wrap">
                        <div id="site-logo">
                            <Link to="/home">
                            {loading ? (
            <p>Loading property data...</p>
          ) : propertyData && imageName ?   (
            <div>
              <img
                src={`/images/advertise/plan2/${imageName || "default-logo.png"}`}
                // Dynamically fetch the logo
                alt="Bhubaneswar Property Logo"
                style={{ width: "220px", height: "auto" }} // Ensure proper styling
              />
              <div className="price">₹ {propertyData.total_price}</div>
              <p>Logo Image ID: {logoId ? logoId : "No logo found"}</p>

            </div>
          ) : (
            <p>No property data found.</p>
          )}
                 
                            </Link>
                        </div>

                        <nav className={`main-menu ${isMenuOpen ? 'open' : ''}`}>
                            <ul>
                                <li><Link to="/home">Home</Link></li>
                                <li className="has-children">
                                    <Link to="javascript:void(0);">Property</Link>
                                    <ul>
                                        <li><Link to="/PropertyList">Property List </Link></li>
                                    </ul>     
                                </li>
                               {/* Flats/Apartments */}
        <li className="has-children">
          <Link to="#">Flat/Apartments</Link>
          <ul>
            {propertyLinks["Flat/Apartment"].map((item, index) => (
              <li key={index}>
                <button onClick={() => handleClick(item.link, item.displayUrl)} className="menu-link">
                  {item.name} Flats
                </button>
              </li>
            ))}
          </ul>
        </li>

        {/* Houses/Villas */}
        <li className="has-children">
          <Link to="#">House/Villas</Link>
          <ul>
            {propertyLinks["House/Villas"].map((item, index) => (
              <li key={index}>
                <button onClick={() => handleClick(item.link, item.displayUrl)} className="menu-link">
                  {item.name} Houses
                </button>
              </li>
            ))}
          </ul>
        </li> 
                                <li><Link to="/ContactUs">Contact Us</Link></li>
                                
                            </ul>
                        </nav>

                        <div className="header-right">
                            <div className="header-call">
                                <div className="icon" style={{ fontSize: '21px' }}>📞</div>
                                <div className="number" style={{ fontSize: '19px', }}>9090212121</div>
                            </div>
                            <div className="header-btn">
                                <Link to="/AddProperty" className="tf-button-default" style={{ fontSize: '18px', }}>
                                    Add Listing
                                </Link>
                            </div>
                        </div>

                        <button className="mobile-nav-toggler" onClick={toggleMenu}>
                            ☰
                        </button>
                    </div>
                </div>
            </header>

                   </>
    );
};

export default HeaderA;
