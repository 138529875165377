import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PropertyList from './pages/PropertyList';
import PropertySingle from './pages/PropertySingle';
import ContactUs from './pages/ContactUs';
import AddProperty from './pages/AddProperty';
import PropertyAdvertise from './pages/PropertyAdvertise';

function Layout({ children }) {
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes('/PropertyAdvertise');

  return (
    <>
      {!hideHeaderFooter && <Header />}
      {children}
      {!hideHeaderFooter && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/propertylist/*" element={<PropertyList />} />
          <Route path="/PropertySingle/:property_no" element={<PropertySingle />} />
          <Route path="/PropertyAdvertise/:property_no" element={<PropertyAdvertise />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AddProperty" element={<AddProperty />} />
          <Route path="*" element={<div>404 - Page Not Found</div>} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
