
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital, faSchool, faTrain, faPlane, faCity, faLandmark } from "@fortawesome/free-solid-svg-icons";

const PropertySingle = () => {
  const { property_no } = useParams(); // Extract property_no from the URL
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPropertyData = async () => {
      setLoading(true); // Set loading to true before fetching
      console.log("Fetching data for property_no:", property_no); // Log the property_no
  
      try {
        const response = await fetch(
          `/api/SingleProperty.php?property_id=${property_no}`
        );
        const result = await response.json();
  
        console.log("API Response:", result); // Log the entire API response
  
        if (result.property_details) {
          setPropertyData(result.property_details); // Corrected access to property data
        } else {
          console.log("No property data found for this property_no");
          setPropertyData(null); // Clear data if no property is found
        }
      } catch (error) {
        console.error("Error fetching property data:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPropertyData();
  }, [property_no]); // Fetch when property_no changes
  
  useEffect(() => {
    if (propertyData) {
      document.title = `${propertyData.title}`;
    } else {
      document.title = "Loading...";
    }
  }, [propertyData]);

  if (loading) {
    return <p>Loading...</p>;
  }
  
  
  
  return (
    <>
     <style>
      {`
      .video {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px; /* Limit the maximum width */
}

.video-wrap iframe {
  width: 100%; /* Make the iframe take the full width of the container */
  height: 500px; /* Set a height for the iframe */
  border: none; /* Remove borders for cleaner look */
}

@media (max-width: 768px) {
  .video-wrap iframe {
    height: 300px; /* Adjust height for smaller screens */
  }

.icon {
  margin-right: 10px;
  font-size: 1.2rem;
  color: #007bff;
}



      `}
     </style>
     <div style={{ marginTop: "95px" }}></div>
      <div id="wrapper">
        <div id="page" className="">
         
          <div className="main-content">
            <div className="property-single-wrap sticky-container" data-sticky-container="">
              <div className="cl-container">
                <div className="row">
                  <div className="col-12">
                    <div className="flex items-center justify-between gap30 flex-wrap pt-30 pb-30">
                      <ul className="breadcrumbs style-1 justify-start">
                        <li><Link to="index.html">Home</Link></li>
                        <li>/</li>
                        <li>Property List</li>
                        <li>/</li>
                        <li>Renovated Apartment</li>
                      </ul>
                      <div className="list-icons-page">
                        <div className="item"><div className="icon"><i className="flaticon-heart" /></div><p>Save</p></div>
                        <div className="item"><div className="icon"><i className="flaticon-before-after" /></div><p>Compare</p></div>
                        <div className="item"><div className="icon"><i className="flaticon-outbox" /></div><p>Share</p></div>
                        <div className="item"><div className="icon"><i className="flaticon-tools-and-utensils" /></div><p>Print</p></div>
                      </div>
                    </div>
                  </div>
                      <div className="col-12">
                      <div className="wrap-gallery-image">
                        <div className="list-tags type-1">
                          <Link to="#" className="tags-item for-sell">
                            FOR RENT
                          </Link>
                          <Link to="#" className="tags-item featured">
                            FEATURED
                          </Link>
                        </div>
                        Link
                  <Link to="/images/house/property-detail-1.jpg"
                  className="item-1"
                  data-fancybox="gallery"
                >
                  <img src="/images/house/property-detail-1.jpg" alt="Property image 1" />
                </Link>
                <Link
                  to="/images/house/property-detail-2.jpg"
                  className="item-2"
                  data-fancybox="gallery"
                >
                  <img src="/images/house/property-detail-2.jpg" alt="Property image 2" />
                </Link>
                <Link
                  to="/images/house/property-detail-3.jpg"
                  className="item-3"
                  data-fancybox="gallery"
                >
                  <img src="/images/house/property-detail-3.jpg" alt="Property image 3" />
                </Link>
                <Link
                  to="/images/house/property-detail-4.jpg"
                  className="item-4"
                  data-fancybox="gallery"
                >
                  <img src="/images/house/property-detail-4.jpg" alt="Property image 4" />
                </Link>
                <Link
                  to="/images/house/property-detail-5.jpg"
                  className="item-5"
                  data-fancybox="gallery"
                >
                  <img src="/images/house/property-detail-5.jpg" alt="Property image 5" />
                </Link>
                <Link
                  to="/images/house/property-detail-3.jpg"
                  className="more-photos"
                  data-fancybox="gallery"
                >
                  <i className="flaticon-gallery" />
                  <p>42 Photos</p>
                </Link>

              </div>
            </div>
                    {loading ? (
                    <p>Loading property data...</p>
                  ) : propertyData ? (
                    <div className="row">
                  
                      <div className="col-xl-8">
                      <div className="content-wrap">
                        <div className="head-title wow fadeInUp">
                          <div>
                            <div className="location">
                              <div className="icon">
                                <i className="flaticon-location" />
                              </div>
                              <h6>
                                 {propertyData.title}
                              </h6>
                            </div>
                          </div>
                          <div>
                            <div className="square">₹ {propertyData.unit_price} /sq ft</div>
                            <div className="price">₹ {propertyData.total_price}</div>

                          </div>
                        </div>
                        <div className="box-items">
                          <div className="item wow fadeInUp">
                            <div className="icon">
                              <i className="flaticon-city" />
                            </div>
                            <div className="text-content">{propertyData.type_of_ownership}</div>
                          </div>
                          <div className="item wow fadeInUp" data-wow-delay="0.1s">
                            <div className="icon">
                              <i className="flaticon-hammer" />
                            </div>
                            <div className="text-content">Built in 1940</div>
                          </div>
                          <div className="item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="icon">
                              <i className="flaticon-minus-front" />
                            </div>
                            <div className="text-content">{propertyData.covered_area} Sq Ft</div>
                          </div>
                          <div className="item wow fadeInUp">
                            <div className="icon">
                              <i className="flaticon-hotel" />
                            </div>
                            <div className="text-content">{propertyData.bedrooms} Bedrooms</div>
                          </div>
                          <div className="item wow fadeInUp" data-wow-delay="0.1s">
                            <div className="icon">
                              <i className="flaticon-bath-tub" />
                            </div>
                            <div className="text-content">{propertyData.bathrooms} Bathrooms</div>
                          </div>
                          <div className="item wow fadeInUp" data-wow-delay="0.2s">
                            <div className="icon">
                              <i className="flaticon-garage" />
                            </div>
                            <div className="text-content">1 Garage</div>
                          </div>
                        </div>
                        <div className="desc">
                          <h4 className="wow fadeInUp">Description</h4>
                          <p className="wow fadeInUp">
                          <p style={{ marginBottom: '10px' }}>{propertyData.brief_description}</p>
  <p style={{ marginBottom: '10px' }}>Inhouse amenities - {propertyData.inhouse_amenities}</p>
  <p>External amenities - {propertyData.external_amenities}</p>
                          </p>
                        </div>
                        <div className="address">
                          <div className="flex items-center justify-between gap30 flex-wrap wow fadeInUp">
                            <h4 className="mb-0">Address</h4>
                            <Link to="#" className="tf-button-green">
                              <i className="flaticon-location" />
                              Open On Google Maps
                            </Link>
                          </div>
                          <div className="list-item">
                            <div className="item wow fadeInUp">
                              <div className="text">Address</div>
                              <p>{propertyData.address}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Zip/Postal Code</div>
                              <p>{propertyData.zipcode}</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">City</div>
                              <p>{propertyData.city}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Area</div>
                              <p>{propertyData.locality}</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">State</div>
                              <p>{propertyData.state}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Country</div>
                              <p>{propertyData.country}</p>
                            </div>
                          </div>
                        </div>
                        <div className="details">
                          <h4 className="wow fadeInUp">Details</h4>
                          <div className="list-item">
                            <div className="item wow fadeInUp">
                              <div className="text">Property ID:</div>
                              <p>{propertyData.property_no}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Garage:</div>
                              <p>1</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">Price:</div>
                              <p>₹{propertyData.total_price}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Garage Size:</div>
                              <p>200 SqFt</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">Property Size:</div>
                              <p>{propertyData.covered_area} Sq Ft</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Year Built:</div>
                              <p>2024</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">Bedrooms:</div>
                              <p>{propertyData.bedrooms}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Property Type:</div>
                              <p>{propertyData.property_type}</p>
                            </div>
                            <div className="item wow fadeInUp">
                              <div className="text">Bathrooms:</div>
                              <p>{propertyData.bathroom}</p>
                            </div>
                            <div className="item wow fadeInUp" data-wow-delay="0.1s">
                              <div className="text">Property Status:</div>
                              <p>{propertyData.rent_sell}</p>
                            </div>
                          </div>
                        </div>


                        <div className="schedule">
                          <h4 className="wow fadeInUp">Schedule a tour</h4>
                          <form className="form-schedule">
                            <div className="cols">
                              <fieldset className="message">
                                <input
                                  type="date"
                                  name="date"
                                  defaultValue="2023-11-20"
                                />
                              </fieldset>
                              <div className="nice-select" tabIndex={0}>
                                <span className="current">Please Select Time</span>
                                <ul className="list">
                                  <li data-value="" className="option selected">
                                    6 AM
                                  </li>
                                  <li data-value="For Ren" className="option">
                                    12 AM
                                  </li>
                                  <li data-value="Sold" className="option">
                                    6 PM
                                  </li>
                                </ul>
                              </div>
                            </div>
                          
                          </form>
                        </div>
                        <div className="video" style={{ width: '100%',  margin: '0 auto 30px auto', maxWidth: '1200px' }}>
  <h4 className="wow fadeInUp">Video</h4>
  {propertyData?.video ? (
    <div
      className="video-wrap"
      style={{ width: '100%' }}
      dangerouslySetInnerHTML={{ __html: propertyData.video }}
    ></div>
  ) : (
    <p>No video available</p>
  )}
</div>

<div className="map">
                          <h4 className="wow fadeInUp">Map</h4>
                          <div className="wrap-map-v1">
                            <div
                              id="map-2"
                              className="row-height"
                              data-map-zoom={16}
                              data-map-scroll="true"
                            />
                          </div>
                        </div>
                        <div className="nearby">
      <h4 className="wow fadeInUp">What's Nearby?</h4>
      {propertyData ? (
        <div className="widget-tabs style-2 type-small">
          <ul className="widget-menu-tab wow fadeInUp">
            <li className="item-title active">
              <span className="inner"><FontAwesomeIcon icon={faHospital} /> Hospitals</span>
            </li>
            <li className="item-title">
              <span className="inner"><FontAwesomeIcon icon={faSchool} /> Schools</span>
            </li>
            <li className="item-title">
              <span className="inner"><FontAwesomeIcon icon={faTrain} /> Railway Stations</span>
            </li>
            <li className="item-title">
              <span className="inner"><FontAwesomeIcon icon={faPlane} /> Airports</span>
            </li>
            <li className="item-title">
              <span className="inner"><FontAwesomeIcon icon={faCity} /> City Center</span>
            </li>
            <li className="item-title">
              <span className="inner"><FontAwesomeIcon icon={faLandmark} /> Landmarks</span>
            </li>
          </ul>
          <div className="widget-content-tab">
            <div className="widget-content-inner active">
              <div className="wrap-nearby">
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faHospital} className="icon" />
                  <div className="title">Nearby Hospital</div>
                  <p>Distance: <span>{propertyData.distance_hospital} km</span></p>
                </div>
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faSchool} className="icon" />
                  <div className="title">Nearby School</div>
                  <p>Distance: <span>{propertyData.distance_school} km</span></p>
                </div>
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faTrain} className="icon" />
                  <div className="title">Nearby Railway Station</div>
                  <p>Distance: <span>{propertyData.distance_railway_station} km</span></p>
                </div>
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faPlane} className="icon" />
                  <div className="title">Nearby Airport</div>
                  <p>Distance: <span>{propertyData.distance_airport} km</span></p>
                </div>
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faCity} className="icon" />
                  <div className="title">City Center</div>
                  <p>Distance: <span>{propertyData.distance_city_center} km</span></p>
                </div>
                <div className="nearby-item wow fadeInUp">
                  <FontAwesomeIcon icon={faLandmark} className="icon" />
                  <div className="title">Landmark</div>
                  <p>Neighbourhood: <span>{propertyData.landmark_neighbourhood}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading nearby information...</p>
      )}
    </div>
                      
                      </div>
                    </div>
                    <div className="col-xl-4">
                      <div className="property-single-sidebar po-sticky">
                        <div className="sidebar-item sidebar-request">
                          <div className="text">
                            Request a tour as early as <br />
                            <span>Today at 11:00AM</span>
                          </div>
                          <Link to="#" className="tf-button-primary w-full">
                            Schedule a Tour
                            <i className="icon-arrow-right-add" />
                          </Link>
                          <Link
                            to="#"
                            className="tf-button-primary w-full style-bg-white"
                          >
                            Contact an agent
                            <i className="icon-arrow-right-add" />
                          </Link>
                        </div>
                      </div>
                    </div> 
                    </div>
                  ) : (
                    <p>No property data found.</p>
                  )} 
                 
                </div>
              </div>
            </div>
            {/* End property-single-wrap */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertySingle;
