import React from "react"

const AddProperty = () => {
  return (
    <>
    <style>
        {`
        
.add-property-container {
  max-width: 1200px; /* Increased form width */
  margin: 120px auto; /* Adds margin at the top */
  padding: 25px;
  background: #fff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); 
  border-radius: 8px;
}

.add-property-container h2 {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Two fields in one row */
.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1; /* Each field takes equal space */
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 650;
  font-size: 15px;
  margin-bottom: 5px;
  color: #555;
}

input,
select,
textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 15px;
}

/* Full width for Address field */
.full-width {
  width: 100%;
  margin-bottom: 15px;
}

textarea {
  resize: none;
}

/* Submit Button */
button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 22px;
  cursor: pointer;
  transition: 0.3s;
  margin-top: 10px;

}

button:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column; /* Stack fields on small screens */
  }
}

        `}
    </style>
    <div className="add-property-container">
      <h2>Add Property</h2>
      <form>
        <div className="form-row">
          <div className="form-group">
            <label>Property Name</label>
            <input type="text" placeholder="Enter property name" />
          </div>
          <div className="form-group">
            <label>Title</label>
            <input type="text" placeholder="Enter title" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Rent / Sell</label>
            <select>
              <option value="Sell">Sell</option>
              <option value="Rent">Rent</option>
            </select>
          </div>
          <div className="form-group">
            <label>Property Type</label>
            <select>
              <option value="apartment-flat">Apartment / Flat</option>
              <option value="house">House</option>
            </select>
          </div>
        </div>

        <div className="form-group full-width">
          <label>Address</label>
          <textarea rows="3" placeholder="Enter full address"></textarea>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Price</label>
            <input type="number" min="1" placeholder="Enter price"  />
          </div>
          <div className="form-group">
            <label>Bedrooms</label>
            <input type="number" min="1" placeholder="Enter number of bedrooms" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Bathrooms</label>
            <input type="number" min="1" placeholder="Enter number of bathrooms" />
          </div>
          <div className="form-group">
            <label>Upload Image</label>
            <input type="file" />
          </div>
        </div>

        <button type="submit" style={{ fontSize: '18px', fontWeight: 'bold' }}>Submit Property</button>
      </form>
    </div>
    </>
  );
};

export default AddProperty;
