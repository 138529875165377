import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

const PropertyList = () => {

  useEffect(() => {
    document.title = "PropertyList";


  // Set meta description
  const metaDescription = document.querySelector('meta[name="description"]');
  const newDescription =
    "Explore a wide range of properties available in Bhubaneswar. Find your dream home with Bhubaneswar Property - Property List.";

  if (metaDescription) {
    metaDescription.setAttribute("content", newDescription);
  } else {
    const metaTag = document.createElement("meta");
    metaTag.name = "description";
    metaTag.content = newDescription;
    document.head.appendChild(metaTag);
  }

  // Set meta keywords
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  const newKeywords = "property list, Bhubaneswar property, real estate, buy property, rent property, house for sale";

  if (metaKeywords) {
    metaKeywords.setAttribute("content", newKeywords);
  } else {
    const keywordTag = document.createElement("meta");
    keywordTag.name = "keywords";
    keywordTag.content = newKeywords;
    document.head.appendChild(keywordTag);
  }
}, []);


  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);

  // Sidebar form states
  const [locationQuery, setLocationQuery] = useState("");
  const [bedroomCount, setBedroomCount] = useState("");
  const [bathroomCount, setBathroomCount] = useState("");
  const [minArea, setMinArea] = useState("");
  const [maxArea, setMaxArea] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [propertyType, setPropertyType] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProperties();
  }, []);

  useEffect(() => {
    const pathSegments = location.pathname.split("/").slice(2); // Remove '/propertylist'
    const params = {};
  
    // Check if the first segment is a property type (avoid empty property type issue)
    if (pathSegments.length > 0 && !pathSegments[0].includes("-")) {
      params.propertyType = pathSegments[0];
      pathSegments.shift(); // Remove property type from segments
    }
  
    // Process remaining filters (bedrooms, bathrooms, etc.)
    pathSegments.forEach((segment) => {
      const parts = segment.split("-");
      const key = parts[0];
      const value = parts.slice(1).join("-"); 
      if (key && value) {
        params[key] = value;
      }
    });
  
    console.log("Extracted Filters:", params); // Debugging
  
    const {
      propertyType,
      bedrooms,
      bathrooms,
      minPrice,
      maxPrice,
      minArea,
      maxArea,
      location: locationFilter
    } = params;
  
    // Format property type for correct comparison
    const formattedType = propertyType
      ? propertyType.replace("-", "/").toLowerCase()
      : null;
  
    // Apply Filters
    if (allProperties.length > 0) {
      const filtered = allProperties.filter((property) => {
        return (
          (!propertyType || 
            (property.property_type &&
             property.property_type.toLowerCase().replace("/", "-") === formattedType)
          ) &&
          (!bedrooms || parseInt(property.bedrooms || 0) === parseInt(bedrooms)) &&
          (!bathrooms || parseInt(property.bathrooms || 0) === parseInt(bathrooms)) &&
          (!minPrice || parseFloat(property.total_price || 0) >= parseFloat(minPrice)) &&
          (!maxPrice || parseFloat(property.total_price || 0) <= parseFloat(maxPrice)) &&
          (!minArea || parseFloat(property.covered_area || 0) >= parseFloat(minArea)) &&
          (!maxArea || parseFloat(property.covered_area || 0) <= parseFloat(maxArea)) &&
          (!locationFilter || property.address?.toLowerCase().includes(locationFilter.toLowerCase()))
        );
      });
  
      setFilteredProperties(filtered);
    }
  }, [location.pathname, allProperties]);
 
  const fetchProperties = () => {
    setLoading(true);
    fetch("https://bhubaneswarproperty.in/api/getProperties.php?limit=20&type=apartment-flat&mode=Sell&city=1&locality=5")
      .then((response) => response.json())
      .then((data) => {
        setAllProperties(Array.isArray(data.data) ? data.data : []);
        setFilteredProperties(Array.isArray(data.data) ? data.data : []);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
        setLoading(false);
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    
    let path = "/propertylist";
    
    if (propertyType) path += `/${propertyType}`; // Add property type only if it's not empty
    if (bedroomCount) path += `/bedrooms-${bedroomCount}`;
    if (bathroomCount) path += `/bathrooms-${bathroomCount}`;
    if (minPrice) path += `/minPrice-${minPrice}`;
    if (maxPrice) path += `/maxPrice-${maxPrice}`;
    if (minArea) path += `/minArea-${minArea}`;
    if (maxArea) path += `/maxArea-${maxArea}`;
    if (locationQuery) path += `/location-${locationQuery}`;
  
    // Remove trailing slashes to ensure clean URLs
    path = path.replace(/\/+/g, "/"); 
  
    navigate(path);
  };
  
  return (
    <>
      <div id="wrapper">
        <div id="page" className="">
          <div className="main-content">
            <div className="flat-title">
              <div className="cl-container full">
                <div className="row">
                  <div className="col-12">
                    <div className="content">
                      <h2>Real Estate &amp; Homes For Sale</h2>
                      <ul className="breadcrumbs">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>/</li>
                        <li>Property List</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="property-list-wrap v1">
              <div className="cl-container">
                <div className="row">
                  <div className="col-lg-4">
                  <form className="form-sidebar-left" onSubmit={handleSearch}>
                <fieldset className="name wow fadeInUp">
                  <input
                    type="text"
                    placeholder="Location"
                    value={locationQuery}
                    onChange={(e) => setLocationQuery(e.target.value)}
                  />
                </fieldset>

                <fieldset
  className="name wow fadeInUp"
  style={{
    marginBottom: '5px',
    width: '100%', // Full width
    backgroundColor: 'white', // White background
    border: '1px solid #ccc', // Optional: Add a light gray border for definition
    borderRadius: '9px', // Optional: Rounded corners
    padding: '7px', // Optional: Add padding
  }}
>
  <select
    style={{
      width: '100%', // Full width for the select element
      padding: '10px', // Add padding for better appearance
      fontSize: '1.4rem', // Adjust font size
      fontWeight: 'normal', // Set font weight to bold
      color: 'black', // Set text color to black
      borderRadius: '5px', // Optional: Add border-radius for rounded corners
      backgroundColor: 'white', // White background
      border: 'none', // Remove default border
    }}
    value={bedroomCount}
    onChange={(e) => setBedroomCount(e.target.value)}
  >
    <option value="">Select Bedrooms</option>
    <option value="1">1 Bedroom</option>
    <option value="2">2 Bedrooms</option>
    <option value="3">3 Bedrooms</option>
    <option value="4">4 Bedrooms</option>
  </select>
</fieldset>

<fieldset
  className="name wow fadeInUp"
  style={{
    marginBottom: '5px',
    width: '100%', // Full width
    backgroundColor: 'white', // White background
    border: '1px solid #ccc', // Optional: Add a light gray border for definition
    borderRadius: '9px', // Optional: Rounded corners
    padding: '7px', // Optional: Add padding
  }}
>
  <select
    style={{
      width: '100%', // Full width for the select element
      padding: '10px', // Add padding for better appearance
      fontSize: '1.4rem',
      fontWeight: 'normal', // Set font weight to bold
      color: 'black', // Set text color to black // Adjust font size
      borderRadius: '5px', // Optional: Add border-radius for rounded corners
      backgroundColor: 'white', // White background
      border: 'none', // Remove default border
    }}
    value={bathroomCount}
    onChange={(e) => setBathroomCount(e.target.value)}
  >
    <option value="">Select Bathrooms</option>
    <option value="1">1 Bathroom</option>
    <option value="2">2 Bathrooms</option>
    <option value="3">3 Bathrooms</option>
    <option value="4">4 Bathrooms</option>
  </select>
</fieldset>


                <fieldset className="name wow fadeInUp">
                  <input
                    type="number"
                    placeholder="Min. Area"
                    value={minArea}
                    onChange={(e) => setMinArea(e.target.value)}
                  />
                </fieldset>
                <fieldset className="name wow fadeInUp">
                  <input
                    type="number"
                    placeholder="Max. Area"
                    value={maxArea}
                    onChange={(e) => setMaxArea(e.target.value)}
                  />
                </fieldset>
                <fieldset className="name wow fadeInUp">
                  <input
                    type="number"
                    placeholder="Min. Price"
                    value={minPrice}
                    onChange={(e) => setMinPrice(e.target.value)}
                  />
                </fieldset>
                <fieldset className="name wow fadeInUp">
                  <input
                    type="number"
                    placeholder="Max. Price"
                    value={maxPrice}
                    onChange={(e) => setMaxPrice(e.target.value)}
                  />
                </fieldset>

                <button className="tf-button-primary w-full wow fadeInUp" type="submit">
                  Search Property
                  <i className="icon-arrow-right-add" />
                </button>
              </form>
                    </div>
                  <div className="col-lg-8">
                    {loading ? (
                      <p>Loading properties...</p>
                    ) : filteredProperties.length === 0 ? (
                      <p>No properties found.</p>
                    ) : (
                      <div className="row">
                        {filteredProperties.map((property, index) => (
                          <div className="col-xl-6" key={index}>
                            <div className="box-dream has-border wow fadeInUp">
                              <div className="image">
                              <img
                              className="w-full"
          src={`https://bhubaneswarproperty.in/images/property_image/thumbnail/${property.parent_id}/${property.image_name}`} // Dynamically construct the image URL
          alt="No Image" // Use the property title as alt text for accessibility
        />
   </div>
                              <div className="content">
                                <div className="head">
                                  <div className="title">
                                    <Link
                                      to={`/PropertySingle/${property.property_no}`}
                                    >
                                      {property.title}
                                    </Link>
                                  </div>
                                  <div className="price">
                                  ₹{property.total_price}
                                  </div>
                                </div>
                                <div className="location">
                                  <p>{property.address}</p>
                                </div>
                                <div className="icon-box">
                                  <div className="item"><i className="flaticon-hotel" />
                                    {property.bedrooms} Beds
                                  </div>
                                  <div className="item"><i className="flaticon-bath-tub" />
                                    {property.bathrooms} Baths
                                  </div>
                                  <div className="item"><i className="flaticon-minus-front" />
                                    {property.covered_area} Sqft
                                  </div>
                                 
                                </div>
                                <Link to={`/PropertySingle/${property.property_no}`} style={{
                marginTop: '4px',          
              display: 'inline-block',
              padding: '8px 13px',
              fontSize: '16px',
              color: '#fff',
              backgroundColor: '#007bff', // Blue color
              border: 'none',
              borderRadius: '5px',
              textDecoration: 'none', // Remove underline
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = '#0056b3'; // Darker blue on hover
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = '#007bff'; // Reset to original color
            }}>
            View Details
          </Link>
 
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyList;
