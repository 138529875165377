import React from 'react';


const ContactUs = () => {

  return (
    <>
    <style>
        {`
            .banner-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
         `}
      </style>

      
    {/* main-content */}
    <div className="main-content spacing-20">
      <div className="wrap-map-v5">
      <img
      src="/images/section/parallax-2.jpg"
      alt="Banner"
      className="banner-image"
    />
        <div className="grid-contact">
          <div className="contact-item wow fadeInUp">
            <div className="icon">
              <i className="flaticon-location-pin" />
            </div>
            <div className="content">
              <h4>Our Address</h4>
              <p>
              Jaydev  Vihar, Bhubaneswar,  <br />
              Odisha
              </p>
            </div>
           
          </div>
          <div className="contact-item wow fadeInUp" data-wow-delay="0.1s">
            <div className="icon">
              <i className="flaticon-phone" />
            </div>
            <div className="content">
              <h4>Contact Info</h4>
              <p>9090212121</p>
            </div>
            
          </div>
          <div className="contact-item wow fadeInUp" data-wow-delay="0.2s">
            <div className="icon">
              <i className="flaticon-video-chat" />
            </div>
            <div className="content">
              <h4>Live Support</h4>
              <p>info@
              bhubaneswarproperty.in</p>
            </div>
          </div>
        </div>
      </div>
      {/* send-message */}
      <section className="tf-section send-message">
        <div className="cl-container">
          <div className="row">
            <div className="col-12">
              <div className="heading-section text-center">
                <h2 className="wow fadeInUp">Send Us a Message</h2>
              </div>
            </div>
          </div>
          <div className="row justify-center">
            <div className="col-xxl-8">
              <form className="form-send-message">
                <div className="cols">
                  <fieldset className="name wow fadeInUp has-top-title">
                    <input
                      type="text"
                      placeholder="Name"
                      className=""
                      name="text"
                      tabIndex={2}
                      defaultValue="Ali Tufan"
                      aria-required="true"
                      required=""
                    />
                    <label htmlFor="">Name</label>
                  </fieldset>
                  <fieldset className="phone wow fadeInUp has-top-title">
                    <input
                      type="number"
                      placeholder="Phone"
                      className=""
                      name="phone"
                      tabIndex={2}
                      defaultValue=""
                      aria-required="true"
                      required=""
                    />
                    <label htmlFor="">Phone</label>
                  </fieldset>
                </div>
                <fieldset className="email wow fadeInUp has-top-title">
                  <input
                    type="email"
                    placeholder="Email"
                    className=""
                    name="email"
                    tabIndex={2}
                    defaultValue="themesflat@gmail.com"
                    aria-required="true"
                    required=""
                  />
                  <label htmlFor="">Email</label>
                </fieldset>
                <fieldset className="message wow fadeInUp has-top-title">
                  <textarea
                    name="message"
                    rows={4}
                    placeholder="Your Message"
                    className=""
                    tabIndex={2}
                    aria-required="true"
                    required=""
                    defaultValue={"Lorem Ipsum"}
                  />
                  <label htmlFor="">Your Message</label>
                </fieldset>
                <div className="checkbox-item wow fadeInUp">
                  <label>
                    <p>
                      I consent to having this website store my submitted
                      information
                    </p>
                    <input type="checkbox" />
                    <span className="btn-checkbox" />
                  </label>
                </div>
                <div className="button-submit wow fadeInUp">
                  <button className="tf-button-primary w-full" type="submit">
                    Send Message
                    <i className="icon-arrow-right-add" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* /send-message */}
    </div>
    {/* /main-content */}
  </>
  
  );
};

export default ContactUs;
