import { Link, useNavigate  } from 'react-router-dom';
 
 
 const Footer = () => {
  const navigate = useNavigate();

  const propertyLinks = {
    "Flat/Apartment": [
      { name: "1 BHK", link: "/propertylist/apartment-flat/bedrooms-1", displayUrl: "/real-estate-1bhk-apartment-flat-for-sale" },
      { name: "2 BHK", link: "/propertylist/apartment-flat/bedrooms-2", displayUrl: "/real-estate-2bhk-apartment-flat-for-sale" },
      { name: "3 BHK", link: "/propertylist/apartment-flat/bedrooms-3", displayUrl: "/real-estate-3bhk-apartment-flat-for-sale" },
      { name: "4 BHK", link: "/propertylist/apartment-flat/bedrooms-4", displayUrl: "/real-estate-4bhk-apartment-flat-for-sale" },
    ],
    "House/Villas": [
      { name: "1 BHK", link: "/propertylist/house-villa/bedrooms-1", displayUrl: "/real-estate-1bhk-independent-house-villa-for-sale" },
      { name: "10 BHK", link: "/propertylist/house-villa/bedrooms-10", displayUrl: "/real-estate-10bhk-independent-house-villa-for-sale" },
      { name: "11 BHK", link: "/propertylist/house-villa/bedrooms-11", displayUrl: "/real-estate-11bhk-independent-house-villa-for-sale" },
      { name: "2 BHK", link: "/propertylist/house-villa/bedrooms-2", displayUrl: "/real-estate-2bhk-independent-house-villa-for-sale" },
      { name: "3 BHK", link: "/propertylist/house-villa/bedrooms-3", displayUrl: "/real-estate-3bhk-independent-house-villa-for-sale" },
      { name: "4 BHK", link: "/propertylist/house-villa/bedrooms-4", displayUrl: "/real-estate-4bhk-independent-house-villa-for-sale" },
      { name: "5 BHK", link: "/propertylist/house-villa/bedrooms-5", displayUrl: "/real-estate-5bhk-independent-house-villa-for-sale" },
      { name: "6 BHK", link: "/propertylist/house-villa/bedrooms-6", displayUrl: "/real-estate-6bhk-independent-house-villa-for-sale" },
    ],
  };

  const handleClick = (actualLink, displayUrl) => {
    navigate(actualLink); // Navigate to the actual working link
    window.history.replaceState(null, "", displayUrl); // Change URL in browser
  };
    return(
   <>
      <style>
        {`
        .footer-link-button {
  background: transparent;
  border: none;
  color: white; /* Ensures text is visible on black background */
  cursor: pointer;
  font-size: 16px;
  padding: 5px 0;
  text-align: left;
  display: block;
  width: 100%;
}

.footer-link-button:hover {
  text-decoration: underline; /* Adds a subtle hover effect */
}

        `}
      </style>
        
             <footer className="footer" >
            <div className="footer-inner">
              <div className="footer-inner-wrap">
                <div className="top-footer">
                  <div className="logo-footer">
                    <Link to="index.html">
                      <img
                        id="logo-footer"
                        src="/images/logo/bhubaneswarproperty-logo.png"
                        alt=""
                        style={{
    width: '220px', // Set the desired width
    height: 'auto', // Maintain the aspect ratio
    }}/>
                    </Link>
                  </div>
                  <div className="wg-social">
                    <span>Follow Us</span>
                    <ul className="list-social">
                      <li>
                        <Link to="#">
                          <i className="icon-facebook" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="icon-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="icon-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="icon-linkedin2" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="center-footer">
                  <div className="footer-cl-1">
                    <div className="ft-title">Subscribe</div>
                    <form className="form-subscribe style-line-bottom">
                      <fieldset className="email">
                        <input
                          type="email"
                          placeholder="Your e-mail"
                          className="style-1"
                          name="email"
                          tabIndex={2}
                          defaultValue=""
                          aria-required="true"
                          required=""
                        />
                      </fieldset>
                      <div className="button-submit style-absolute-right">
                        <button className="tf-button-bg" type="submit">
                          Send <i className="icon-arrow-right-add" />
                        </button>
                      </div>
                    </form>
                    <div className="text">
                      Subscribe to our newsletter to receive our weekly feed.
                    </div>
                  </div>
                  <div className="footer-cl-2">
                    <div className="ft-title">Flat/Apartments</div>
                    <ul className="navigation-menu-footer">
                    {propertyLinks["Flat/Apartment"].map((item, index) => (
                  <li key={index}>
                    <button
                      className="footer-link-button"
                      onClick={() => handleClick(item.link, item.displayUrl)}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
 
                      
                    </ul>
                  </div>
                  <div className="footer-cl-3">
                    <div className="ft-title">House/Villas</div>
                    <ul className="navigation-menu-footer">
                    {propertyLinks["House/Villas"].map((item, index) => (
                  <li key={index}>
                    <button
                      className="footer-link-button"
                      onClick={() => handleClick(item.link, item.displayUrl)}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
                                         
                    </ul>
                  </div>
                  <div className="footer-cl-4">
                    <div className="ft-title">Contact Us</div>
                    <ul className="navigation-menu-footer">
                      <li>
                        <div className="text">info@
                        bhubaneswarproperty.in 9090212121</div>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-cl-5">
                    <div className="ft-title">Our Address</div>
                    <ul className="navigation-menu-footer">
                      <li>
                        <div className="text">
                          Jaydev  Vihar, Bhubaneswar, Odisha
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-cl-6">
                    <div className="ft-title">Get the app</div>
                    <ul className="ft-download">
                      <li>
                        <Link to="#">
                          <div className="icon">
                            <i className="icon-appleinc" />
                          </div>
                          <div className="app">
                            <div>Download on the</div>
                            <div>Apple Store</div>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <div className="icon">
                            <i className="icon-ch-play" />
                          </div>
                          <div className="app">
                            <div>Get in on</div>
                            <div>Google Play</div>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="bottom-footer">
                  <div className="text">Copyright © 2024. JustHome</div>
                </div>
              </div>
            </div>
          </footer>
         
        </>
    );
 }

 export default Footer;